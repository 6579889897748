<template>
	<div class="box_oa">
		<div style="display: inline-block; width: 100%; height: 100%">
			<div class="child-top_a" style="padding-top: 10px">
				<el-button class="brotherBtns last-child" @click="save">
					<i class="iconfont iconbaocun" style="color: #0055e9"></i>
					<span style="margin-left: 5px">保存</span>
				</el-button>
			</div>

			<div class="limi_page" v-loading="tableLoading">
				<div class="limiPage_left">
					<div class="searchinput">
						<el-input
							class="left-child-input left-child-input-content"
							clearable
							placeholder="请输入账号或姓名"
							style="width: 95%; float: left; margin-bottom: 5%"
							v-model="searchKey"
							@keydown.enter.native="create(1)"
						>
							<el-button
								@click="create(1)"
								icon="el-icon-search"
								slot="append"
								style="color: #fff; background: #0055e9; border-top-left-radius: 0; border-bottom-left-radius: 0"
							></el-button>
						</el-input>
					</div>
					<div style="clear: both"></div>
					<p v-if="functionType" class="noData">加载中</p>
					<el-menu class="el-menu-vertical-demo" @select="select" :default-active="defaultUuids" :unique-opened="true" v-else>
						<el-submenu index="1">
							<template slot="title">
								<span>角色</span>
							</template>
							<el-menu-item-group style="overflow-y: scroll; max-height: 300px">
								<el-menu-item v-for="(item, index) in rolesData" :key="index" :index="item.uuid" :title="item.name">{{ item.name }}</el-menu-item>
							</el-menu-item-group>
						</el-submenu>
						<el-submenu index="2">
							<template slot="title">
								<span>账号</span>
							</template>
							<el-menu-item-group style="overflow-y: scroll; max-height: 300px">
								<el-menu-item v-for="(item, index) in usersData" :key="index" :index="item.uuid" :title="item.name">{{ item.name }}</el-menu-item>
							</el-menu-item-group>
						</el-submenu>
					</el-menu>
				</div>

				<div class="limiPage_right" v-loading="loading">
					<el-tabs :stretch="true" value="first" class="topTab">
						<el-tab-pane label="功能权限" name="first" class="tabLeft">
							<!-- <p v-if="this.functionalLists == ''" class="noData">加载中</p> -->
							<!-- 功能权限 -->
							<el-tree
								empty-text=""
								:data="functionalLists"
								class="editorTree"
								:props="defaultProps"
								show-checkbox
								:expand-on-click-node="false"
								:node-key="'uuid'"
								:icon-class="'iconfont iconjia'"
								:default-checked-keys="defaultCheckedKeys"
								ref="permissionTree"
							></el-tree>
							<!-- :default-expanded-keys="defaultCheckedKeys" -->
						</el-tab-pane>
						<el-tab-pane label="数据权限" name="second" class="tabRight">
							<div class="treeSous">
								业务类型
								<el-select v-model="treeValue" placeholder="请选择">
									<el-option label="人员数据" value="1"></el-option>
									<el-option label="账号角色数据" value="2"></el-option>
								</el-select>
							</div>
							<!-- 人员数据 -->
							<div v-show="treeValue == 1" class="tree_right">
								<el-tree
									:data="accountLists"
									class="editorTree"
									:node-key="'uk_uuid'"
									:props="defaultProps"
									show-checkbox
									:icon-class="'iconfont iconjia'"
									:expand-on-click-node="false"
									:default-checked-keys="accountDefaultKeys"
									ref="accountBusiness"
								></el-tree>
								<!-- :default-expanded-keys="accountDefaultKeys" -->
							</div>
							<!-- 账号角色数据 -->
							<div v-show="treeValue == 2" class="tree_right">
								<el-tree
									:data="rolesLists"
									class="editorTree"
									:node-key="'uuid'"
									:props="defaultProps"
									show-checkbox
									:expand-on-click-node="false"
									:icon-class="'iconfont iconjia'"
									v-if="show"
									:default-checked-keys="roleDefaultKeys"
									ref="rolesBusiness"
								></el-tree>
								<!-- :default-expanded-keys="roleDefaultKeys" -->

								<el-tree
									:data="userLists"
									class="editorTree"
									:node-key="'uuid'"
									:props="defaultProps"
									:expand-on-click-node="false"
									v-if="show"
									show-checkbox
									:icon-class="'iconfont iconjia'"
									:default-checked-keys="userDefaultKeys"
									ref="userBusiness"
								></el-tree>
								<!-- :default-expanded-keys="userDefaultKeys" -->
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			type_uuid: '',
			type: 'role',
			value: '',
			functionalLists: [],
			accountLists: [],
			usersData: [],
			rolesData: [],
			userLists: [
				{
					name: '账户',
					can_check: 1,
					checked: 0,
					children: [],
				},
			],
			rolesLists: [
				{
					name: '角色',
					can_check: 1,
					checked: 0,
					children: [],
				},
			],
			defaultProps: {
				children: 'children',
				label: 'name',
				disabled: function (data, node) {
					if (data.can_check == 0) {
						return true
					}
				},
			},
			defaultCheckedKeys: [],
			accountDefaultKeys: [],
			roleDefaultKeys: [],
			userDefaultKeys: [],
			treeValue: '1',
			show: false,
			loading: false,
			tableLoading: false,
			defaultUuids: '',
			defaultUuidsKeys: [],
			functionType: true,
			funcType: false,
			searchKey: '',
		}
	},
	created() {},
	mounted() {
		this.create()
	},
	methods: {
		create() {
			this.tableLoading = true
			// 列表数据
			let paramse = {
				name: this.searchKey,
			}
			this.$axios
				.get(`/p/permissionSet/userRoles?name=${this.searchKey}`)
				.then(res => {
					if (res.data.code === 0) {
						var data = res.data.data
						this.rolesData = []
						this.usersData = []
						for (var val in data.roles) {
							var obj = {
								name: data.roles[val],
								uuid: val,
							}
							this.rolesData.push(obj)
						}
						for (var val in data.users) {
							var obj = {
								name: data.users[val],
								uuid: val,
								nameAcount: data.users[val].account + '(' + data.users[val].name + ')',
							}
							this.usersData.push(obj)
						}
						// if (this.rolesData.length !== 0 && this.usersData.length !== 0) {
						this.functionType = false
						// }
						this.defaultUuids = this.rolesData[0].uuid
						this.select(this.defaultUuids)
					} else {
						this.$utils.message({
							type: 'error',
							message: res.data.message,
						})
					}
					this.tableLoading = false
				})
				.catch(error => {
					console.log(error)
				})
			this.tableLoading = false
		},
		// 保存
		save() {
			// 功能权限
			var data = this.$refs.permissionTree.getCheckedKeys() // 功能权限
			var arr = JSON.stringify(data)
			// 人员数据
			var accountdata = this.$refs.accountBusiness.getCheckedNodes(),
				newaccountdata = {} // 人员数据
			if (accountdata !== []) {
				accountdata.forEach((item, index) => {
					var str = item.uk_uuid.split('/')
					if (str.length > 1) {
						if (Object.keys(newaccountdata).length !== 0) {
							var type = false
							for (var val in newaccountdata) {
								if (val === str[0]) {
									type = true
									newaccountdata[val].push(str[1])
								}
							}
							if (!type) {
								newaccountdata[str[0]] = []
								newaccountdata[str[0]].push(str[1])
							}
						} else {
							newaccountdata[str[0]] = []
							newaccountdata[str[0]].push(str[1])
						}
					} else {
						newaccountdata[str[0]] = []
					}
				})
			}
			var jsonaccountdata = JSON.stringify(newaccountdata)
			// 角色数据
			var roleData = this.$refs.rolesBusiness.getCheckedKeys()
			roleData.forEach((item, index) => {
				if (item == undefined) {
					roleData.splice(index, 1)
				}
			})
			var roleArr = JSON.stringify(roleData)

			// 用户数据
			var userData = this.$refs.userBusiness.getCheckedKeys()
			userData.forEach((item, index) => {
				if (item == undefined) {
					userData.splice(index, 1)
				}
			})
			var userArr = JSON.stringify(userData)

			this.$axios
				.post(
					'/p/permissionSet/permissionsSet',
					this.$qs({
						type: this.type == 'role' ? '1' : '2',
						data_uuid: this.type_uuid,
						menus_uuids: arr,
						staffs: jsonaccountdata,
						roles: roleArr,
						users: userArr,
					})
				)
				.then(res => {
					if (res.data.code === 0) {
						this.$utils.message({
							type: 'success',
							message: res.data.message,
						})
					} else {
						this.$utils.message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		// 点击左侧树 获取权限数据
		select(index, indexPath) {
			this.loading = true
			var data = {},
				type = false
			this.type_uuid = index
			this.treeValue = '1'
			this.show = false
			this.rolesData.forEach(item => {
				if (item.uuid === index) {
					this.type = 'role'
					type = true
					data.role_uuid = index
				}
			})
			if (!type) {
				data.user_uuid = index
				this.type = 'user'
			}

			this.defaultCheckedKeys = []
			this.accountDefaultKeys = []
			this.userDefaultKeys = []
			this.roleDefaultKeys = []

			this.$axios
				.post(type ? '/p/permissionSet/getRolePermissions' : '/p/permissionSet/getUserpermissions', this.$qs(data))
				.then(res => {
					if (res.data.code === 0) {
						// 人员数据
						this.accountLists = res.data.data.datas.department_staffs
						this.getCheckaccount(this.accountLists, this.accountDefaultKeys)
						// 用户数据
						this.rolesLists[0].children = res.data.data.datas.roles
						this.userLists[0].children = res.data.data.datas.users

						this.getCheckuser(this.userLists[0].children)
						this.getCheckrole(this.rolesLists[0].children)
						// 角色数据

						// 功能权限
						// this.functionalLists = res.data.data.menus;
						// this.getCheck(this.functionalLists);

						// res.data.data.menus.forEach(item =>{
						//    if (item.children) {
						//      item.children.forEach(j=>{
						//        if(j.children){
						//          j.children.forEach((o, index)=>{
						//            if(o.name ===" "){
						//              j.children.splice(index, 1)
						//            }else{
						//               // console.log(o.name);
						//            }
						//          })

						//        }
						//      })
						//    }
						// // 功能权限
						//    return this.functionalLists = res.data.data.menus
						// })

						for (let i = 0; i < res.data.data.menus.length; i++) {
							const a = res.data.data.menus[i]
							if (a.children) {
								for (let k = 0; k < a.children.length; k++) {
									const b = a.children[k]
									if (b.children) {
										for (let z = b.children.length - 1; z >= 0; z--) {
											const c = b.children[z]
											if (c.name == ' ') {
												// b.children.splice(z, 1)
												console.log(b.children.splice(z, 1))
											}
										}
									}
								}
							}
						}
						this.functionalLists = res.data.data.menus
						// console.log(this.functionalLists);
						this.getCheck(this.functionalLists)
					} else {
						this.$utils.message({
							type: 'error',
							message: res.data.message,
						})
					}
					this.loading = false
				})
				.catch(error => {
					console.log(error)
				})
		},
		// 功能权限
		getCheck(data) {
			data.forEach(item => {
				if (item.checked) {
					this.defaultCheckedKeys.push(item.uuid)
				}
				if (item.children) {
					this.getCheck(item.children)
				}
			})
		},
		// 人员数据
		getCheckaccount(data) {
			data.forEach(item => {
				if (item.checked) {
					this.accountDefaultKeys.push(item.uk_uuid)
				} else {
					if (item.children) {
						this.searchChildrenaccount(item.children)
					}
				}
			})
		},
		searchChildrenaccount(item) {
			item.forEach(items => {
				if (items.checked) {
					this.accountDefaultKeys.push(items.uk_uuid)
				} else {
					if (items.children) {
						this.searchChildrenaccount(items.children)
					}
				}
			})
		},
		// 账户
		getCheckuser(data) {
			data.forEach((item, index) => {
				if (item.checked) {
					this.userDefaultKeys.push(item.uuid)
				}
			})
			this.show = true
		},
		// 人员
		getCheckrole(data) {
			data.forEach((item, index) => {
				if (item.checked) {
					this.roleDefaultKeys.push(item.uuid)
				}
			})
		},
		// 默认选中
		defaultIndex(index) {
			return '1-' + index
		},
		// 禁用
		disabled(data, node) {
			if (data.can_check == 0) {
				return true
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.box_oa {
	background-color: #fff;
}
.child-top_a {
	// background-color: #f4f6f9;
	margin: 0.52083rem 2.04167rem;
	// justify-content: end;
	height: 53px;
	.input-from {
		float: left;
		min-width: 850px;
		margin-bottom: 0;
	}

	.operation-button {
		float: right;
	}
}
.brotherBtns {
	float: right;
	margin-right: 25px;
}
.limi_page {
	width: 95%;
	min-height: 600px;
	margin: 15px auto 0;
	display: flex;
	justify-content: space-between;
	.limiPage_left {
		width: 30%;
		height: 520px;
		overflow: auto;
		padding: 20px 0 0 20px;
		background: #f4f6f9;
		position: relative;
		.searchinput {
			// position: absolute;
			top: 1%;
		}
		.el-tree {
			background: #f4f6f9;
		}
		.el-menu-vertical-demo {
			border-right: none;
			.el-submenu {
				background: #f5f7fa;
				// height: 453px;
				// overflow-x: scroll;
				// overflow-y: scroll;
				.el-submenu__title {
					span {
						font-size: 16px;
					}
				}
				.el-menu {
					width: 98%;
					overflow: hidden;
					background: #f5f7fa;
					border-right: none;
					.el-menu-item-group {
						background: #f5f7fa;
						.el-menu-item-group__title {
							padding: 0;
						}
						.el-menu-item {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							word-break: break-all;
							height: 32px;
							line-height: 32px;
							font-size: 15px;
						}
					}
				}
			}
		}
		.option_tree {
			width: 80%;
			margin: 20px auto 0;
			color: #999999;
			&:last-child {
				margin-bottom: 20px;
			}
			p {
				margin-top: 10px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				color: #000;
				&:hover {
					cursor: pointer;
					color: #4bb3ff;
				}
			}
		}
	}
	.limiPage_right {
		width: 60%;
		height: 540px;
		background: #f4f6f9;
		.tree_right {
			height: 350px;
			// overflow: auto;
			margin-top: 20px;
		}
	}
}

.el-menu-vertical-demo {
	li {
		/deep/ .el-submenu__title {
			height: 40px;
			line-height: 40px;
		}
		/deep/ .el-menu-item-group__title {
			padding: 0;
		}
	}
}
/deep/.el-menu-item.is-active {
	color: #0055e9;
}
/deep/.topTab .el-tabs__item.is-top.is-active {
	color: #0055e9;
}
/deep/.topTab .el-tabs__nav .el-tabs__active-bar::after {
	background: #0055e9;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
	background-color: #0055e9;
	border-color: #0055e9;
}
/deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
	background-color: #0055e9;
	border-color: #0055e9;
}
</style>

<style lang="scss">
.leftTrees {
	.icon-zhankai-icon {
		font-size: 16px;
	}
	/deep/ .el-tree-node__label {
		font-size: 16px;
	}

	.el-tree-node {
	}
	.el-checkbox {
		.el-checkbox__inner {
			width: 16px;
			height: 16px;
		}
		.el-checkbox__inner:after {
			height: 10px;
			left: 5px;
		}
	}
}
.topTab {
	.el-tabs__content {
		background: #f4f6f9;
		padding: 30px;
		.el-tree-node {
			background: #f4f6f9;
		}
		.icon-zhankai-icon {
			font-size: 16px;
		}
		/deep/ .el-tree-node__label {
			font-size: 16px;
		}
		.el-checkbox {
			.el-checkbox__inner {
				width: 16px;
				height: 16px;
			}
			.el-checkbox__inner:after {
				height: 10px;
				left: 5px;
			}
		}
	}
	.el-tabs__item.is-top.is-active {
		color: #4bb3ff;
	}
	.el-tabs__nav {
		padding: 10px 0;
		.el-tabs__active-bar {
			background: transparent;
		}
		// .is-active{
		// 	color: #4BB3FF;
		// }
		.el-tabs__item {
			font-size: 14px;
			color: #626262;
		}
		.el-tabs__active-bar::after {
			background: #4bb3ff;
			display: block;
			content: ' ';
			// height: 100%;
			// width: 100%;
			position: absolute;
			z-index: -1;
			top: 50%;
			left: 51%;
			height: 1px;
			transform: translateX(-50%) translateY(-50%);
			width: 30%;
			height: 100%;
		}
	}

	.tabLeft {
		height: 400px;
		overflow: auto;
		.el-tree {
			// width: 20%;
			margin-left: 60px;
			background: #f4f6f9;
		}
	}
	.tabRight {
		height: 450px;
		overflow-y: auto;
		.treeSous {
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #626262;
			.el-input {
				width: 100%;
				margin-left: 10px;
			}
		}
		.el-tree {
			// width: 20%;
			margin-left: 80px;
			// margin-top: 10px;
			background: #f4f6f9;
		}
	}
}
.noData {
	text-align: center;
	color: #909399;
	font-size: 14px;
}
</style>

<style lang="scss">
.editorTree {
	// width: 20%;
	.expanded {
		transform: rotate(0deg);
	}
	.expanded::before {
		content: '\e6d1';
	}
}
</style>
